<template>
  <div class="withdraw-container">
    <div class="header">
      <el-button
        type="primary"
        style="float: left;"
        :disabled="multipleSelection.length === 0"
        @click="multipleAudit"
        >批量审核</el-button
      >
      <el-row type="flex" justify="end">
        <el-col :span="5">
          <el-row type="flex">
            <el-col :span="12">用户名：</el-col>
            <el-col :span="12">
              <el-input v-model="userName"></el-input>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="5">
          <el-row type="flex">
            <el-col :span="12">用户ID：</el-col>
            <el-col :span="12">
              <el-input v-model="userid"></el-input>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="5">
          <span class="label">类型：</span>
          <el-select v-model="withdrawType">
            <el-option
              v-for="item in typeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-col>
        <el-col :span="5">
          <span class="label">状态：</span>
          <el-select v-model="status">
            <el-option
              v-for="item in statusOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-col>
        <el-col :span="3">
          <el-button type="primary" @click="search">搜索</el-button>
        </el-col>
      </el-row>
    </div>
    <el-table
      :data="records.items"
      border
      stripe
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column
        v-for="col in columns"
        :prop="col.id"
        :key="col.id"
        :label="col.label"
        :width="col.width"
        :formatter="col.formatter"
      ></el-table-column>
      <el-table-column label="操作" width="260" align="center">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="primary"
            :disabled="!(+scope.row.status <= 0)"
            @click="handleAudit(scope.row)"
          >
            审核
          </el-button>
          <el-button
            size="mini"
            type="success"
            @click="handleDetail(scope.row)"
          >
            明细
          </el-button>
          <el-button
            size="mini"
            type="danger"
            :disabled="!(+scope.row.status === 0)"
            @click="handleAuditReject(scope.row)"
          >
            拒绝
          </el-button>
          <el-button size="mini" type="warning" @click="handleLucky(scope.row)">
            幸运值
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="currentPage"
      :page-sizes="[200, 50, 100]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="records.total"
      background
      @size-change="sizeChange"
      @current-change="currentChange"
    ></el-pagination>
    <el-drawer :visible.sync="showDrawer" direction="rtl" size="70%">
      <el-col :span="5">
        <el-row type="flex" align="middle">
          <el-col :span="12">冻结金额：</el-col>
          <el-col :span="12">{{ amountInvalid }}</el-col>
        </el-row>
      </el-col>
      <el-table :data="inviteList" class="invite-list-table">
        <el-table-column
          property="user_id"
          label="用户id"
          width="100"
        ></el-table-column>
        <el-table-column
          property="mobile"
          label="手机"
          width="120"
        ></el-table-column>
        <el-table-column
          property="email"
          label="邮箱"
          width="200"
        ></el-table-column>
        <el-table-column
          property="created_at"
          label="创建时间"
          width="210"
        ></el-table-column>
      </el-table>
    </el-drawer>

    <el-dialog
      :title="'设置幸运值'"
      :visible.sync="showCreateDialog"
      width="30%"
      @close="showCreateDialog = false"
    >
      <el-form ref="form" :model="postForm" label-width="80px">
        <el-select v-model="luckyValue" placeholder="请选择">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form>
      <span slot="footer">
        <el-button @click="showCreateDialog = false">取 消</el-button>
        <el-button type="primary" @click="onSubmit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  auditWithdraw,
  getWithdrawRecordInfo,
  editLucky,
} from "@/api/withdraw";

export default {
  mounted() {
    this.getRecords();
  },
  data() {
    return {
      data: [],
      options: [
        {
          value: "0",
          label: "不中奖",
        },
        {
          value: "1",
          label: "只中最低",
        },
        {
          value: "2",
          label: "正常",
        },
      ],
      luckyValue: "0",
      columns: [
        { id: "id", label: "ID", width: 130 },
        { id: "user_id", label: "用户ID", width: 130 },
        { id: "user_name", label: "用户名", width: 180 },
        {
          id: "coin_type",
          label: "币种",
          formatter: (row) => {
            switch (+row.coin_type) {
              case 1:
                return "BSV";
              case 2:
                return "ETH";
              default:
                return row.coin_type;
            }
          },
        },
        { id: "amount", label: "提现金额" },
        {
          id: "withdraw_type",
          label: "类型",
          formatter: (row) => {
            switch (+row.withdraw_type) {
              case 2:
                return "每周活动提现";
              case 3:
                return "集羊活动提现";
              default:
                return row.withdraw_type;
            }
          },
        },
        { id: "info", label: "审核信息" },
        { id: "pay_txid", label: "交易tx" },
        {
          id: "status",
          label: "状态",
          width: 70,
          formatter: (row) => {
            switch (+row.status) {
              case 0:
                return "待审核";
              case 1:
                return "审核通过";
              case 2:
                return "开始打款";
              case 9:
                return "打款成功";
              case -1:
                return "审核拒绝";
              case -2:
                return "打款失败";
              default:
                return row.status;
            }
          },
        },
        { id: "time", label: "时间", width: 160 },
      ],
      statusOptions: [
        { value: "", label: "全部" },
        { value: "0", label: "待审核" },
        { value: "1", label: "审核通过" },
        { value: "2", label: "开始打款" },
        { value: "9", label: "打款成功" },
        { value: "-1", label: "审核拒绝" },
        { value: "-2", label: "打款失败" },
      ],
      typeOptions: [
        { value: "", label: "全部" },
        { value: "2", label: "每周活动" },
        { value: "3", label: "集羊活动" },
      ],
      status: "0",
      withdrawType: "",
      currentPage: 1,
      pageSize: 200,
      showDrawer: false,
      showCreateDialog: false,
      inviteList: [],
      amountInvalid: 0,
      multipleSelection: [],
      userid: "",
      userName: "",
      inviteUserName: "",
      postForm: {},
    };
  },
  computed: {
    ...mapGetters(["records"]),
  },
  methods: {
    search(val) {
      this.currentPage = 1;
      this.getRecords();
    },
    async handleAudit(row) {
      try {
        await this.$confirm(
          `审核用户【${row.user_name}】提现：${row.amount} ` +
            (+row.coin_type == 1 ? "sat" : "Gwei"),
          "提示",
          {
            confirmButtonText: "通过",
            cancelButtonText: "取消",
            type: "warning",
          }
        );
        this.audit({ id: row.id, status: 1 });
      } catch (error) {
        console.log(error);
      }
    },
    async audit(data) {
      const { status } = data;
      try {
        const res = await this.$prompt(
          status === 1 ? "请输入通过原因" : "请输入拒绝原因",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
          }
        );
        let result = await auditWithdraw({ ...data, info: res.value });
        if (+result.data.code) {
          this.$message({ type: "error", message: "操作失败!" });
          this.getRecords();
        } else {
          this.auditSuccess();
        }
      } catch (error) {
        console.log(error);
      }
    },
    async handleDetail(row) {
      const loadingInstance = this.$loading();
      try {
        const { data } = await getWithdrawRecordInfo({
          user_id: row.user_id,
          withdraw_id: row.id,
        });
        this.showDrawer = true;
        this.amountInvalid = data.amountInvalid;
        this.inviteList = data.data;
        loadingInstance.close();
      } catch (error) {
        loadingInstance.close();
        this.$message({ type: "error", message: "查询失败!" });
      }
    },
    async handleAuditReject(row) {
      this.audit({ id: row.id, status: -1 });
    },
    auditSuccess() {
      this.$message({ type: "success", message: "操作成功!" });
      this.getRecords();
    },
    getRecords() {
      const data = {
        page: this.currentPage,
        page_size: this.pageSize,
      };
      if (this.status !== "") {
        data.status = this.status;
      }
      if (this.withdrawType !== "") {
        data.withdraw_type = this.withdrawType;
      }
      if (this.userid) {
        data.user_id = this.userid;
      }
      if (this.userName) {
        data.username = this.userName;
      }
      if (this.inviteUserName) {
        data.invite_user_name = this.inviteUserName;
      }
      this.$store.dispatch("withdraw/getRecords", data);
    },
    sizeChange(size) {
      this.pageSize = size;
      this.getRecords();
    },
    currentChange(page) {
      this.currentPage = page;
      this.getRecords();
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    async multipleAudit() {
      const ids = this.multipleSelection.map((item) => item.id).join(",");
      if (ids === "") {
        return;
      }
      try {
        await this.$confirm(
          `批量审核${this.multipleSelection.length}条，是否通过？`,
          "提示",
          {
            confirmButtonText: "通过",
            cancelButtonText: "拒绝",
            type: "warning",
          }
        );
        this.audit({ id: ids, status: 1 });
      } catch (error) {
        if (error === "cancel") {
          this.audit({ id: ids, status: -1 });
        }
      }
    },
    handleLucky(row) {
      this.showCreateDialog = true;
      this.postForm = {
        user_id: row.user_id,
      };
    },
    handleSuccess() {
      this.showCreateDialog = false;
      this.luckyValue = "0";
      this.postForm = {};
      this.getRecords();
    },
    onSubmit() {
      this.postForm.lucky = this.luckyValue;
      editLucky(this.postForm).then((data) => {
        if (+data.data.code) {
          this.$message({
            message: data.data.msg,
            duration: 3000,
            type: "error",
          });
          this.handleSuccess();
        } else {
          this.$message({
            message: data.data.msg,
            duration: 3000,
            type: "success",
          });
          this.handleSuccess();
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.invite-list-table {
  max-height: 100vh;
  overflow-y: scroll;
}
.withdraw-container {
  margin: 20px;

  .el-pagination {
    padding: 20px;
    text-align: right;
  }

  .header {
    margin-bottom: 20px;
    text-align: right;
    margin-right: 20px;
    font-size: 14px;

    > .label {
      margin-right: 12px;
    }
  }
}
</style>
